import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
    CenteredQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const GebetUndMeditation = ({ data }) => (
    <InnerPage title="Gebet und Meditation  " description="">
        <IntroHolder
            title="Gebet und Meditation"
            subtitle="Gebet und Meditation sind für die geistige Gesundheit lebenswichtig"
            picture={data.introImage}
            breadcrumbs={{
                grandparent: 'Woran Bahá’í glauben',
                parent: 'Die Natur des Menschen',
                'current-item': 'Gebet und Meditation',
            }}
        >
            <IntroQuote>
                Würdest du nur einen Tautropfen der kristallklaren Wasser
                göttlicher Erkenntnis erlangen, so würdest du alsbald gewahr
                werden, dass wahres Leben nicht das Leben des Fleisches, sondern
                das Leben des Geistes ist.
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Gebet, Meditation, ebenso wie Fasten, Pilgerreise und der{' '}
                    <Link to="/gemeinsames-handeln/andacht-und-dienst/">
                        Dienst{' '}
                    </Link>{' '}
                    am Nächsten stärken die einzigartige Beziehung des Einzelnen
                    und der{' '}
                    <Link to="/woran-bahai-glauben/der-einzelne-und-die-gesellschaft/die-gemeinschaft/">
                        Gemeinschaft
                    </Link>{' '}
                    zu Gott. Diese stärken den Einzelnen und beleben die
                    Beziehungen, die den Zusammenhalt in einer Gesellschaft
                    aufrechterhalten.
                </p>
                <div>
                    <p>
                        Für unsere geistig-spirituelle Entwicklung ist das Gebet
                        lebenswichtig. Durch das Gebet loben und danken wir
                        Gott, zeigen unsere Liebe zu Ihm und flehen Ihn um
                        Beistand an. Eine der besonderen Eigenschaften des
                        Menschen ist die Fähigkeit zur Meditation. Die
                        Bahá’í-Schriften besagen, dass für den menschlichen
                        Fortschritt Reflektieren und Nachsinnen unverzichtbar
                        sind.
                    </p>
                </div>
                <div>
                    <p>
                        So wie unser Körper Nahrung braucht, um sich angemessen
                        zu entwickeln, benötigen wir regelmäßiges Beten für
                        unsere geistige Nahrung und Gesundheit. Das Gebet ist
                        damit Nahrung für die Seele. Es stärkt die Liebe zu Gott
                        in unseren Herzen und zieht uns näher zu Ihm hin.
                    </p>
                </div>
                <div>
                    <p>
                        Gebet in seiner höchsten Form ist der reine Ausdruck
                        liebevoller Hinwendung zu Gott. Dennoch ist es nur allzu
                        verständlich, dass wir oft Gottes Hilfe erbitten und
                        erflehen. Nach einem solchen Gebet werden wir nachdenken
                        und nach dem handeln, was uns als der beste Weg
                        erscheint, in der Hoffnung, dass unsere Bemühungen
                        bestätigt werden. Wir sollten volles Vertrauen in die
                        Barmherzigkeit Gottes haben und darauf, dass Er das
                        gewähren wird, was für uns am besten ist. In Momenten
                        der persönlichen Reflexion verwenden wir natürlich oft
                        eigenen Worte, um mit unserem Schöpfer zu kommunizieren.
                        In den Schriften des Báb, Bahá’u’lláhs und
                        ‘‘Abdu’l-Bahás finden Menschen zahlreiche wunderschöne
                        Gebete, die oft genau ihre innersten Gedanken und
                        Gefühle ausdrücken – Gebete, die sowohl in der
                        persönlichen Andacht wie auch in Andachtsversammlungen
                        gesprochen werden können. Bahá’í beginnen und beenden
                        üblicherweise auch Zusammenkünfte, in denen
                        beispielsweise die verschiedenen Gemeindeangelegenheiten
                        besprochen werden, mit Gebeten.
                    </p>
                </div>
                <div>
                    <p>
                        <Link to="/woran-bahai-glauben/bahaullah-und-sein-anspruch/bahaullah/">
                            Bahá’u’lláh
                        </Link>{' '}
                        hat einige Gebete mit besonderer Kraft ausgestattet.
                        Dazu gehören drei Pflichtgebete, die von Ihm offenbart
                        wurden: ein kurzes Gebet mit nur wenigen knappen Zeilen,
                        das zwischen Mittag und Sonnenuntergang zu sprechen ist;
                        ein mittleres Pflichtgebet mit mehreren Versen, das am
                        Morgen, am Mittag und am Abend gesprochen werden sollte;
                        und ein langes Gebet, das einmal in vierundzwanzig
                        Stunden gesprochen wird. Die Bahá’í können für sich
                        auswählen, welches dieser drei Gebete sie sprechen
                        möchten.
                        <br />
                        Auch versammeln sich auf der ganzen Welt Bahá’í mit
                        ihren Freunden und Nachbarn in Zentren oder privaten
                        Wohnstätten, um zu beten. Neben dem Lesen von Auszügen
                        aus heiligen Schriften und anderer inspirierender Texte,
                        werden solche{' '}
                        <Link to="/gemeinsames-handeln/andacht-und-dienst/andachtsversammlungen/">
                            Andachtsversammlungen
                        </Link>{' '}
                        oft durch Musik und Gesang bereichert. Solche Treffen
                        sollen Geistigkeit in den Teilnehmern wecken und ein
                        Gemeindeleben fördern, das von einer andächtigen Haltung
                        geprägt ist.
                    </p>
                </div>
                <CenteredQuote>
                    Haltet Versammlungen, lest und singt die himmlischen Lehren,
                    damit die Stadt erleuchtet werde mit dem Lichte der
                    Wirklichkeit und jenes Land ein wahres Paradies werde durch
                    die Kraft des Heiligen Geistes;[…]und die Melodie der
                    Einheit und Solidarität der Menschenwelt muss in Ost und
                    West jedes Ohr erreichen.
                    <br />
                    ‘Abdu’l-Bahá
                </CenteredQuote>
                <Reference>
                    <p>1. Bahá'u'lláh</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default GebetUndMeditation;

export const pageQuery = graphql`
    query {
        introImage: file(
            relativePath: { eq: "Kachel_Andacht-und-Gebet-a.jpg" }
        ) {
            ...fluidImage
        }
    }
`;
